import {
  UPDATE_SELECTED_OPTIONS,
  UPDATE_CURRENT_SECTION,
  RESET_QUESTIONNAIRE,
  LOAD_QUESTIONNAIRE_FROM_DRAFT,
} from '../actions/questionnaire';

const initialState = {
  selectedOptions: new Map(),
  currentSectionIndex: 0,
};

const questionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_OPTIONS: {
      const { questionId, answer } = action.payload; // answer could be an array or single answer object {optionId: '1', responseText: 'Yes'}
      const updatedSelectedOptions = new Map(state.selectedOptions);
      updatedSelectedOptions.set(questionId, answer);
      return {
        ...state,
        selectedOptions: updatedSelectedOptions,
      };
    }
    case UPDATE_CURRENT_SECTION:
      return {
        ...state,
        currentSectionIndex: action.payload,
      };
    case LOAD_QUESTIONNAIRE_FROM_DRAFT: {
      const data = action.payload;
      const draftAnswers = data.draft;
      const selectedOptions = new Map();

      Object.keys(draftAnswers).forEach(questionId => {
        const answer = draftAnswers[questionId];
        selectedOptions.set(questionId, answer);
      });
      const currentSectionIndex =
        data.currentSectionIndex >= 0 ? data.currentSectionIndex : 0;
      return {
        ...state,
        currentSectionIndex: currentSectionIndex,
        selectedOptions: selectedOptions,
      };
    }
    case RESET_QUESTIONNAIRE:
      return initialState;
    default:
      return state;
  }
};

export default questionnaireReducer;
