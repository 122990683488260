import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { KYCPage, SecurityOverview } from 'pages/Account';
import { onboardingStatus } from 'utils/enums/onboardingStatus';
import { Questionaire } from './Questionaire';
import { Documents } from './Documents';
import { ComplianceQuestionnaire } from './ComplianceQuestionnaire';

const Onboarding = () => {
  const { onboarding } = useSelector(({ user }) => user.profile);
  const { current_step: currentStep } = onboarding;
  const {
    TWOFA,
    KYC,
    QUESTIONNAIRE,
    COMPLIANCE_QUESTIONNAIRE,
    DOCUMENTS,
  } = onboardingStatus;

  useEffect(() => {}, [currentStep]);

  if (currentStep === TWOFA) return <SecurityOverview />;
  if (currentStep === KYC) return <KYCPage />;
  if (currentStep === DOCUMENTS) return <Documents />;
  if (currentStep === QUESTIONNAIRE) return <Questionaire />;
  if (currentStep === COMPLIANCE_QUESTIONNAIRE) return <ComplianceQuestionnaire />;
  return <></>;
};

export default Onboarding;
